import ChartistGraph from "react-chartist";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Descriptions} from "antd";


/**
 * const pieData = [
 *     {label: 'Test', value: 10, percentage: '10%', color: 'red'}
 * ];
 * @param app_state
 * @param pieClassName
 * @param data
 * @param isCurrency
 * @param description
 * @param legends
 * @returns {Element}
 * @constructor
 */
const FxPieChart = ({app_state, pieClassName = 'fx-pie-chart', data = [], isCurrency = false, description = false, legends = false}) => {

    const [componentState, setComponentState] = useState({
        pieData: {
            labels: [],
            series: []
        }
    })

    // const drawChart = () => {
    //     const pie = document.querySelector(pieClassName)
    //     // const legendContainer = document.getElementById(`chart-legend`);
    //     // legendContainer.innerHTML = "";
    //     if (pie) {
    //         const series = pie.querySelectorAll('.ct-series');
    //         const labels = data.map(i => i.label);
    //
    //         series.forEach((series, index) => {
    //             const legendItem = document.createElement('div');
    //             legendItem.classList.add('legend-item');
    //
    //             legendItem.style.display = 'flex';
    //             legendItem.style.alignItems = 'center';
    //             legendItem.style.marginBottom = '5px';
    //             legendItem.style.marginRight = '10px';
    //
    //             const labelElement = document.createElement('span');
    //             const label = labels[index];
    //             labelElement.textContent = labels[index]; // Add corresponding label
    //
    //             // if (label != null) {
    //             //     const img = document.createElement('img');
    //             //     img.setAttribute('src', `https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${label.toLowerCase()}.png`);
    //             //     img.setAttribute('width', '15px');
    //             //     img.setAttribute('height', '10px');
    //             //     img.style.marginRight = '5px';
    //             //     legendItem.appendChild(img)
    //             // }
    //             // legendItem.appendChild(labelElement);
    //             // legendContainer.appendChild(legendItem);
    //         })
    //     }
    // }

    const calculatePercentage = (label, index) => {
        const totalValue = data.reduce((sum, item) => sum + item.value, 0);
        const percentage = ((data[index].value / totalValue) * 100).toFixed(2) + '%';
        return `${label} = ${percentage}`
    }

    const prepareChart = () => {
        let labels = data.map(i => i.label);
        const series = data.map(i => i.value);

        labels = labels.map((label, index) => calculatePercentage(label, index));

        setComponentState(prev => ({...prev, pieData: {...prev.pieData, labels: labels, series: series}}));
    }

    const renderValue = (value) => {
        if (isCurrency) {
            return new Intl.NumberFormat('en-GB', {
                    currency: 'AUD',
                    style: 'currency',
                    useGrouping: true,
                    currencyDisplay: 'narrowSymbol',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }).format(value);
        } else {
            return value;
        }
    }

    useEffect(() => {
        if (!app_state.current_client) return true;
        if (data.length > 0) {
            prepareChart();
        }
    }, [data, app_state.current_client]);

    useEffect(() => {
        // drawChart();
    }, [componentState.pieData])

    return <>
        <ChartistGraph data={componentState.pieData}
                       style={{width: '200px'}}
                       className={pieClassName}
                       listener={{
                           draw: (graphData) => {
                               if (graphData.type === 'slice' && data.length > 0) {
                                   const sliceIndex = graphData.index; // Get the slice index (0, 1, 2, ...)
                                   graphData.element.attr({
                                       style: `fill: ${data[sliceIndex].color};` // Blue color for first series
                                   });
                               }
                           }
                       }}
                       options={{
                           height: 250,
                           labelInterpolationFnc: (value) => value,
                           showLabel: true
                       }} type={'Pie'}/>
        {
            legends ?
                <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                    {data.map((item, index) => (
                        <div key={index} style={{margin: '0 15px', textAlign: 'center'}}>
                            <div style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: item.color,
                                display: 'inline-block',
                                marginRight: '8px'
                            }}></div>
                            <span>{item.label}: {item.percentage}</span>
                        </div>
                    ))}
                </div> : <></>
        }
        {
            description
                ? <div style={{display: 'flex', justifyContent: 'center'}}>
                    {<Descriptions column={1} contentStyle={{margin: 0, padding: 0}}>
                        {data.map(i => (
                            <Descriptions.Item style={{marginBottom: 0, paddingBottom: 0}}
                                               label={i.label}>
                                {renderValue(i.value)}
                            </Descriptions.Item>))}
                    </Descriptions>}
                    {/*<div>*/}
                    {/*    {*/}
                    {/*        !pieCharts.currencyAverage.isUncoveredZero &&*/}
                    {/*        <Alert type={'info'} message={'Uncovered is Zero'}></Alert>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
                : <></>
        }
    </>

}

const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FxPieChart);