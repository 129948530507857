import dayjs from "dayjs";
import {Button, message, Modal, Tag} from "antd";
import {CheckSquareOutlined, CloseSquareOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {API} from "aws-amplify";
import React from "react";
import {CurrencyElement} from "./ExposureMonitorDetail";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";


const prepareDefaultColumns = (getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, history, setComponentState) => {
    return [
        // {
        //     key: 'id',
        //     title: 'Record #',
        //     dataIndex: 'id'
        // },
        {
            key: 'date',
            title: 'Payment Scheduled',
            dataIndex: 'date',
            sorter: (a, b) => {
                const dateA = new Date(a.date.split('/').reverse().join('/'));
                const dateB = new Date(b.date.split('/').reverse().join('/'));
                return dateA - dateB;
            },
            className: 'ant-table-column-sort-new',
            defaultSortOrder: 'ascend', ...getColumnSearchProps({
                dataIndex: 'date',
                filterInputType: 'DATE',
                render: (text, record) => dayjs(text).format('DD/MM/YYYY')
            })
        },
        {
            key: 'bid',
            title: 'Beneficiary Name',
            dataIndex: 'bid',
            render: (text, record) => {
                const ben = componentState.beneficiaryList.find(i => i.id === text);
                if (ben != null) {
                    return ben.nickname == null ? ben.ben_legal_name : ben.nickname
                }
                return '(pay to currency balance)'
            }
        },
        {
            key: 'amount',
            title: 'Payment Amount',
            dataIndex: 'amount',
            render: (text, record) => {
                const currency = app_state.currency_list.find(i => i.id === record.currencyID);
                if (currency && text !== null) {
                    return <CurrencyElement
                        currency={currency.iso_alpha_3}
                        value={text}
                        classes={classes}
                    />
                } else {
                    return '-'
                }
            }
        },
        {
            key: 'type',
            title: '',
            dataIndex: 'type',
            render: (text, record) => {
                if (text === 'PAYOUTS') {
                    return <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <div style={{display: 'flex', justifyContent: 'start', gap: 5}}>
                            {onEditBtnClick ?  <Button type={'primary'} size="small" icon={<EditOutlined/>}
                                                       onClick={() => onEditBtnClick(record.id)}></Button> : <></>}
                            {
                                onEditBtnClick && <Button type={'primary'} size="small" icon={<DeleteOutlined/>} danger={true}
                                                          onClick={() => {
                                                              Modal.confirm({
                                                                  title: `Delete ?`,
                                                                  content: `Are you sure, you want to delete this payout record #${record.id} ?`,
                                                                  onOk: () => {
                                                                      const loading = message.loading("Removing. Please wait..", 0);
                                                                      API.post("commons", "/update", {
                                                                          body: {
                                                                              context: record.identifier,
                                                                              data: {deleted: true},
                                                                              condition: {id: record.id}
                                                                          }
                                                                      }).then(() => {
                                                                          initData();
                                                                          message.success("Removed");
                                                                      }).catch(err => {
                                                                          console.log(err);
                                                                          message.success("Failed");
                                                                      }).finally(() => loading())
                                                                  }
                                                              })
                                                          }}></Button>
                            }
                            {/*<Button type={'info'} size="small" onClick={() => {*/}
                            {/*    const from_currency = 'AUD';*/}
                            {/*    const to_currency = app_state.currency_list.find(i => i.id === record.currencyID).iso_alpha_3;*/}
                            {/*    const bid = record.bid || '-'; // just to ${bid} where to put it below.*/}
                            {/*    history.push(`/shortcut_transfer/${from_currency}-${to_currency}--${record.amount}-----`);*/}
                            {/*}}>Add Cover (Spot)</Button>*/}
                            {
                                componentState.dataState === 'ALL' || componentState.dataState === 'ACTUAL'
                                    ? <>
                                        {
                                            sendForward && <Button type={'primary'} size="small" onClick={() => {
                                                const values = record;
                                                values.payment_due_date = record.date;
                                                values.from_currency = 1;
                                                values.to_currency = record.currencyID;
                                                values.description = 'Via payout';
                                                values.beneficiary_id = record.bid;
                                                sendForward(values);
                                            }}>Add Cover</Button>
                                        }
                                    </>
                                    : <>
                                        <Button type={'primary'} size="small" onClick={() => {
                                            const values = record;
                                            values.settlementDate = record.date;
                                            values.currencyFromID = 1;
                                            values.currencyToID = record.currencyID;
                                            values.amountTo = record.amount;

                                            setComponentState(prev => ({...prev, isDraftForwardModelOpen: true}));
                                            componentState.populateDraftForwardForm(values);
                                        }}>Add Cover</Button>
                                    </>
                            }
                        </div>
                    </div>
                } else {
                    if (componentState.dataState === 'ALL' || componentState.dataState === 'ACTUAL') {
                        if (text === 'FORWARDS') {
                            return <Button type={'info'} size={'small'}
                                           onClick={() => history.push(`/forwards/detail/${record.id}`)}>View Forward</Button>
                        } else {
                            return <Button type={'info'} size={'small'}
                                           onClick={() => history.push(`/transfers/edit/${record.id}`)}>View Transfer</Button>
                        }
                    }
                }
            }
        }
    ];
}
export const ExposureMonitorUtils = {
    buildPayoutColumns: (getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState) => {
        let columns = prepareDefaultColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, history, setComponentState);
        if (onEditBtnClick == null) {
            columns = columns.filter(i => i.dataIndex !== 'type');
        }
        const column = {
            key: 'attachments',
            title: 'Attachments',
            dataIndex: 'attachments',
            render: (text, record) => {
                if (record.attachments.length > 0) {
                    return <Tag style={{ cursor: 'pointer'}} onClick={() => onViewDocumentsBtnClick(record.attachments)} icon={<CheckSquareOutlined />} color={'success'}></Tag>
                } else {
                    return <Tag style={{ cursor: 'pointer'}} icon={<CloseSquareOutlined />} color={'warning'}></Tag>
                }
            }
        }
        columns.push(column);
        return columns;
    },
    buildCoveredColumns: (getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history, setComponentState) => {

        const columns = prepareDefaultColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, history, setComponentState);

        const draftColumn = {
            key: 'draft',
            title: 'Actual/Forecast',
            dataIndex: 'draft',
            render: (text, record) => {
                return <>{record.draft ? 'Forecast Coverage' : 'Actual Cover'}</>
            }
        }

        const currencyFromCol = {
            key: 'currencyFromId',
            title: 'Currency From',
            dataIndex: 'currencyFromId',
            render: (text, record) => {
                const currency = app_state.currency_list.find(i => i.id === text);
                if (currency) {
                    return <TypographyCurrency
                        iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
                }
                return <></>
            }
        };
        const currencyToCol = {
            key: 'currencyID',
            title: 'Currency To',
            dataIndex: 'currencyID',
            render: (text, record) => {
                const currency = app_state.currency_list.find(i => i.id === text);
                if (currency) {
                    return <TypographyCurrency
                        iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
                }
                return <></>
            }
        };

        const rateColumn = {
            key: 'rate',
            title: 'Rate',
            dataIndex: 'rate',
            render: (text, record) => text != null ? text.toFixed(4) : '-'
        };

        const amountFrom = {
            key: 'amount_from',
            title: 'Amount From',
            dataIndex: 'amount_from',
            render: (text, record) => {
                const currency = app_state.currency_list.find(i => i.id === record.currencyFromId);
                if (currency && text !== null) {
                    return <CurrencyElement
                        currency={currency.iso_alpha_3}
                        value={text}
                        classes={classes}
                    />
                } else {
                    return '-'
                }
            }
        }
        // columns.push(draftColumn, currencyFromCol, currencyToCol, rateColumn, amountFrom);
        columns.push(draftColumn, currencyFromCol, rateColumn, currencyToCol);
        return columns;
    },
    buildDraftColumns: (getColumnSearchProps, componentState, app_state, sendForward, classes) => {
       return [];
    },
    prepareDescriptions: (data, state, renderValue, haBalance = null) => {
        const descriptions = [];

        const payoutActual = data.filter(item => item.type === 'PAYOUTS' & !item.draft).reduce((acc, item) => acc + item.amount, 0);
        let coveredActual = data.filter(item => item.type !== 'PAYOUTS' && !item.draft).reduce((acc, item) => acc + item.amount, 0);
        let uncoveredActual = payoutActual - coveredActual
        const totalActual = uncoveredActual + coveredActual;
        coveredActual = coveredActual + (haBalance ? haBalance : 0);
        uncoveredActual = uncoveredActual - (haBalance ? haBalance : 0);

        const payoutDraft = data.filter(item => item.type === 'PAYOUTS' && item.draft).reduce((acc, item) => acc + item.amount, 0);
        let coveredDraft = data.filter(item => item.type !== 'PAYOUTS' && item.draft).reduce((acc, item) => acc + item.amount, 0);
        const uncoveredDraft = payoutDraft - coveredDraft;
        const totalDraft = uncoveredDraft + coveredDraft;

        const payoutActualDraft = data.filter(item => item.type ===  'PAYOUTS').reduce((acc, item) => acc + item.amount, 0);
        let coveredActualDraft = data.filter(item => item.type !== 'PAYOUTS').reduce((acc, item) => acc + item.amount, 0);
        let uncoveredActualDraft = payoutActualDraft - coveredActualDraft
        const totalActualDraft = uncoveredActualDraft + coveredActualDraft;
        coveredActualDraft = coveredActualDraft + (haBalance ? haBalance : 0);
        uncoveredActualDraft = uncoveredActual - (haBalance ? haBalance : 0);


        const actual = [
            {label: 'Actual Payments', value: renderValue ?  renderValue(totalActual) : totalActual, key: 'ACTUAL'},
            {label: 'Actual Covered', value: renderValue ?  renderValue(coveredActual) + (haBalance ? ' (Includes HA Balance)' : '') : coveredActual, key: 'ACTUAL'},
            {label: 'Uncovered Amount', value: renderValue ?  renderValue(uncoveredActual) : uncoveredActual, key: 'ACTUAL'},
        ];

        const draft = [
            {label: 'Forecast Payments', value: renderValue ?  renderValue(totalDraft) : totalDraft, key: 'DRAFT'},
            {label: 'Forecast Cover', value: renderValue ?  renderValue(coveredDraft) + (haBalance ? ' (Includes HA Balance)' : '') : coveredDraft, key: 'DRAFT'},
            {label: 'Uncovered Amount', value: renderValue ?  renderValue(uncoveredDraft) : uncoveredDraft, key: 'DRAFT'},
        ];

        const all = [
            {label: 'Total Payments', value: renderValue ?  renderValue(totalActualDraft) : totalActualDraft, key: 'ALL'},
            {label: 'Total Covered', value: renderValue ?  renderValue(coveredActualDraft) + (haBalance ? ' (Includes HA Balance)' : '') : coveredActualDraft, key: 'ALL'},
            {label: 'Total Uncovered', value: renderValue ?  renderValue(uncoveredActualDraft) : uncoveredActualDraft, key: 'ALL'},
        ];

        const filterByState = (items, state) => {
            return items.filter(item => item.key === state || state === 'ALL');
        };

        descriptions.push(
            ...filterByState(actual, state),
            ...filterByState(draft, state),
            ...filterByState(all, state)
        );
        return descriptions
    }
}